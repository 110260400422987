<template>
  <form class="loginDiv" method="post" @submit.prevent="handleSubmit">
    <div class="background-image-div" :style="{ backgroundImage: 'url(' + require('@/assets/images/vasion-logo-texture.png') + ')'}">
      <div class="innerLoginDiv">
        <div class="w100 icon-fill">
          <VasionLogoWhite class="vasion-wordmark" />
        </div>
        <div class="loginSpacer32" />
        <div v-if="samlEnabled" class="login-button-div">
          <VasionButton
            id="saml-login-submit"
            :isFullWidth="true"
            :classProp="'secondary'"
            @vasionButtonClicked="samlRedirect"
          >
            <span>Sign In with SAML</span>
          </VasionButton>
          <div class="login-divider"><span>or</span></div>
        </div>
        <div v-if="!needsPasswordReset && !requestPasswordReset" key="login">
          <label class="loginLabelStyle">USERNAME</label>
          <input
            id="username"
            v-model="username"
            class="loginTextBox"
            :class="{ loginTextBoxError: loginError }"
            type="text"
            placeholder="Username"
            autocomplete="on"
            required
            @input="clearLoginError"
          >
          <div class="loginSpacer24" />
          <label class="loginLabelStyle">PASSWORD</label>
          <input
            id="password"
            v-model="password"
            class="loginTextBox"
            :class="{ loginTextBoxError: loginError }"
            type="password"
            placeholder="Password"
            autocomplete="off"
            required
            @input="clearLoginError"
          >
          <div class="loginSpacer24" />
          <div class="login-button-div">
            <VasionButton
              id="login-submit"
              :isFullWidth="true"
              :is-submit="true"
              :classProp="'primary'"
              :isDisabled="invalidLoginParams"
            >
              <span v-if="!isLoading">Sign In</span>
              <div v-else id="loader-box">
                <Loading
                  :active.sync="isLoading"
                  :is-full-page="false"
                  loader="dots"
                  :color="'white'"
                  :background-color="'#3D2562'"
                />
              </div>
            </VasionButton>
            <div id="forgot-password-button" @click="requestPasswordReset = !requestPasswordReset">
              Forgot your password?
            </div>
            <h4 v-if="loginError" id="invalid-password-notification">
              <VasionCircleError class="vasion-circle-error" />
              <div class="error-login">
                {{ showLoginError }}
              </div>
            </h4>
          </div>
        </div>
        <div v-if="needsPasswordReset" key="passwordReset">
          <label class="loginLabelStyle">NEW PASSWORD</label>
          <VasionInput
            id="newPassword"
            inputType="top-black"
            v-model="newPassword"
            placeholder="New Password"
            specialType="password"
            :canToggleShowPassword="true"
            :isInErrorState="validationError"
          />
          
          <label class="validationErrorMessage">{{ validationErrorMessage }}</label>
          <div class="loginSpacer24" />
          <label class="loginLabelStyle">CONFIRM PASSWORD</label>
          <VasionInput
            id="confirmPassword"
            inputType="top-black"
            v-model="confirmPassword"
            placeholder="Confirm Password"
            specialType="password"
            :canToggleShowPassword="true"
            :isInErrorState="validationError"
          />
          <div class="loginSpacer24" />
          <div class="login-button-div">
            <VasionButton
              id="login-submit"
              :isFullWidth="true"
              :is-submit="true"
              :classProp="'primary'"
              :isDisabled="invalidPasswordResetParams"
            >
              Update Password
            </VasionButton>
          </div>
        </div>
        <div v-if="requestPasswordReset && !needsPasswordReset" key="requestPasswordReset">
          <label class="loginLabelStyle">USERNAME</label>
          <input
            id="request-reset-password-username"
            v-model="username"
            class="loginTextBox"
            :class="{ loginTextBoxError: validationError }"
            type="text"
            placeholder="Username"
            autocomplete="on"
            required
          >
          <label class="validationErrorMessage">{{ validationErrorMessage }}</label>
          <div class="loginSpacer24" />
          <label class="loginLabelStyle">EMAIL</label>
          <input
            id="request-reset-password-email"
            v-model="email"
            class="loginTextBox"
            :class="{ loginTextBoxError: validationError }"
            type="email"
            placeholder="Email"
            required
          >
          <div class="loginSpacer24" />
          <div class="login-button-div center">
            <VasionButton
              v-if="!isLoading"
              id="login-submit"
              :classProp="'primary'"
              @vasionButtonClicked="requestPasswordReset = !requestPasswordReset"
            >
              Cancel
            </VasionButton>
            <VasionButton
              id="login-submit"
              :isFullWidth="true"
              :classProp="'primary'"
              :isDisabled="invalidRequestPasswordResetParams"
              @vasionButtonClicked="requestResetPassword()"
            >
              <span v-if="!isLoading">Reset Password</span>
              <div v-else id="loader-box">
                <Loading
                  :active.sync="isLoading"
                  :is-full-page="false"
                  loader="dots"
                  :color="'white'"
                  :background-color="'#ff4702'"
                />
              </div>
            </VasionButton>
          </div>
        </div>
        <div class="loginSpacer24" />
        <span class="privacy">We use analytics to improve the application performance, for more information see Vasion's <a href="https://vasion.com/cookie-information">Cookie</a> and  <a href="https://vasion.com/privacy-policy">Privacy</a> Policy.</span>
        <div class="loginSpacer32" />
        <!--
        <div class='w100'>
          <input class="keep-me-signed-in-checkbox" id="chkKeepMeSignedIn" type="checkbox"><label class='loginLabelStyle'>Keep me signed in</label>
        </div>
        <div class='loginSpacer24'></div>
        <div class='w100'>
          <label id="lblForgotPassword" class='loginLabelStyle vasion-cursor'>Forgot Password?</label>
        </div>
        -->
      </div>
      <VasionSnackbar
        id="login-snack"
        :showSnackbarBool.sync="showSnackbarBool"
        :snackbarImage="snackbarImage"
        :snackbarSubTitle="snackbarSubTitle"
        :snackbarTitle="snackbarTitle"
      />
      <VasionMSAModal
        id="vasion-msa-modal"
        :showModal="showMSA"
        @yesButtonClick="msaModalYes"
        @noButtonClick="msaModalNo"
      />
    </div>
  </form>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import VasionMSAModal from '@/components/shared/VasionMSAModal.vue'
import moment from 'moment'

export default {
  name: 'TheLogin',
  components: {
    Loading,
    VasionMSAModal,
  },
  data: function () {
    return {
      attemptSignIn: true,
      changePasswordError: false,
      confirmPassword: '',
      credentials: { Username: '', Password: '' },
      email: '',
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      loginError: false,
      newPassword: '',
      password: '',
      passwordResetFromEmail: false,
      requestPasswordReset: false,
      samlEnabled: false,
      showMSA: false,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      username: '',
      validationError: false,
      validationErrorMessage: '',
    }
  },
  computed: {
    backgroundUrl() {
      // eslint-disable-next-line
      return require('@/assets/images/vasion-logo-texture.png')
    },
    defaultApp() { return this.$store.state.admin.defaultApp },
    defaultAppLocation() {
      let location = ''
      switch (this.defaultApp) {
        case 'admin':
          location = 'TheEmptyAdmin'
          break
        case 'capture':
          location = 'StartScan'
          break
        case 'signature':
          location = 'TheSignatureInbox'
          this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
          break
        case 'reports':
          location = 'TheEmptyReport'
          break
        case 'storage':
          location = 'VaultFolders'
          break
        case 'workspace':
          location = 'TheWorkspaceHome'
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break;
        case 'forms':
          location = 'AllForms'
          break;
        case 'templates':
          this.$store.dispatch('common/getEmailTemplates')
          location = 'AllEmailTemplates'
          this.$store.dispatch('mainViews/changeMainNav', 'all-emails')
          break;
        case 'automation':
          location = 'TheAutomationHome'
          break;
        case 'workflow':
          this.$store.dispatch('workflow/selectWorkflow', 0)
          location = 'AllWorkflows'
          this.$store.dispatch('mainViews/changeMainNav', 'workflow-designer')
          break;
        default:
          location = 'TheWorkspaceHome'
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break
      }
      return location
    },
    firstAccessibleAppLocation() {
      let location = ''
      if (this.$store.getters['common/APP_ACCESS_WORKSPACE']) {
        location = 'TheWorkspaceHome'
        this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
      } else if (this.$store.getters['common/APP_ACCESS_REPORTS']) {
        location = 'TheEmptyReport'
      } else if (this.$store.getters['common/APP_ACCESS_STORAGE']) {
        location = 'VaultFolders'
      } else if (this.$store.getters['common/APP_ACCESS_CAPTURE']) {
        location = 'StartScan'
      } else if (this.$store.getters['common/APP_ACCESS_FORMS']) {
        location = 'AllForms'
      } else if (this.$store.getters['common/APP_ACCESS_SIGNATURE']) {
        location = 'TheSignatureInbox'
        this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
      } else if (this.$store.getters['common/APP_ACCESS_WORKFLOW']) {
        this.$store.dispatch('workflow/selectWorkflow', 0)
        location = 'AllWorkflows'
        this.$store.dispatch('mainViews/changeMainNav', 'workflow-designer')
      } else if (this.$store.getters['common/APP_ACCESS_TEMPLATES']) {
        this.$store.dispatch('common/getEmailTemplates')
        location = 'AllEmailTemplates'
        this.$store.dispatch('mainViews/changeMainNav', 'all-emails')
      } else if (this.$store.getters['common/APP_ACCESS_AUTOMATION']) {
        location = 'TheAutomationHome'
      } else if (this.$store.getters['common/APP_ACCESS_ADMIN']) {
        location = 'TheEmptyAdmin'
      }
      return location
    },
    invalidLoginParams() { return !this.username || !this.password },
    invalidPasswordResetParams() { return !this.newPassword || !this.confirmPassword || this.newPassword !== this.confirmPassword || this.newPassword.trim().length === 0 },
    invalidRequestPasswordResetParams() { return !this.email || !this.username },
    needsPasswordReset() { return this.$store.state.common.needsPasswordReset || this.passwordResetFromEmail },
    samlSSOUrl() { return this.$store.state.admin.samlSSOUrl },
    showLoginError() { return this.$store.state.common.loginError ? this.$store.state.common.loginError : "Invalid username or password." },
  },
  created: async function () {
    if (this.$route.params.resetPasswordGuid) {
      if (await this.$store.dispatch('common/resetPasswordCheckGuid', { Value: this.$route.params.resetPasswordGuid })) {
        this.passwordResetFromEmail = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'The password reset link is expired.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    }
    // Check for a SAML SSO URL to be configured, and if it is, enable the SSO login
    await this.$store.dispatch('admin/getSamlSSOUrl')
    this.samlEnabled = this.samlSSOUrl !== '' && this.samlSSOUrl !== null && this.samlSSOUrl !== undefined
  },
  methods: {
    async attemptPasswordReset() {
      // need to make sure the password matches the validation criteria sent from the server
      await this.attemptPasswordValidation()

      if (this.validationError === false) {
        let responseString = ''
        if (this.passwordResetFromEmail) {
          responseString = await this.$store.dispatch('common/resetPassword', { GUID: this.$route.params.resetPasswordGuid, Password: this.newPassword })
          this.passwordResetFromEmail = false
        } else {
          responseString = await this.$store.dispatch('common/setNewPasswordForReset', this.newPassword)
        }
        if (responseString) {
          this.validationErrorMessage = responseString
          this.validationError = true
        } else {
          await this.$store.dispatch('common/setPasswordResetState', 'false')
          this.routeFromSuccessfulLogin()
        }
      }
      this.isLoading = false
    },
    async attemptPasswordValidation() {
      // gets password schema from server
      const serverPasswordSchema = await this.$store.dispatch('common/getPasswordSchema')
      // checks to make sure user enter password matches criteria sent from server
      const confirmPassword = this.confirmPasswordCriteria(serverPasswordSchema, this.newPassword)
      // creating an array with any validation issues the password might have. If created array is empty
      // password is valid, otherwise this array will contain the errors and use the switch statement below
      // to provide an error message
      const passwordValidationObject = Object.values(confirmPassword).filter(item => item !== null)
      // if the length of the array returned from the filter above is 0 then password passes criteria and validation error = false
      // otherwise this results to true and the v-if on the validation error label is triggered
      this.validationError = passwordValidationObject.length !== 0
      // this resets the validation error message if they fixed the issue or updates the message if there was a different issue
      // if the issue wasn't fixed this stays the same
      this.validationErrorMessage = this.validationError === false ? '' : this.validationErrorMessage
      if (this.validationError === true) {
        switch (passwordValidationObject[0]) {
          case 'upperAndLowerCase':
            this.validationErrorMessage = 'New password must have upper and lowercase letters.'
            break
          case 'atLeastOneNumber':
            this.validationErrorMessage = 'New password must have at least one number.'
            break
          case 'atLeastOneNonAlphaNumeric':
            this.validationErrorMessage = 'New password must have at least one special character.'
            break
          case 'maxChars':
            this.validationErrorMessage = `Your new password can only be ${serverPasswordSchema.maxChars} characters long.`
            break
          case 'minChars':
            this.validationErrorMessage = `Your new password should be at least ${serverPasswordSchema.minChars} characters long.`
            break
          default:
            break
        }
      }
    },
    confirmPasswordCriteria(serverSchema, password) {
      // eslint-disable-next-line
      const regex = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g)
      const schema = {
        upperAndLowerCase: serverSchema.upperAndLowerCase === true ? password.match(/[a-z]/g) !== null && password.match(/[A-Z]/g) !== null : true,
        atLeastOneNumber: serverSchema.atLeastOneNumber === true ? password.match(/[0-9]/g) !== null : true,
        atLeastOneNonAlphaNumeric: serverSchema.atLeastOneNonAlphaNumeric === true ? regex.test(password) === true : true,
        maxChars: serverSchema.maxChars === 0 ? true : password.length <= serverSchema.maxChars,
        minChars: password.length >= serverSchema.minChars,
      }

      return Object.entries(schema).map((rule) => {
        return rule[1] === false ? rule[0] : null
      })
    },
    async clearLoginError() { this.loginError = false },
    async handleSubmit() {
      this.isLoading = true
      this.loginError = false

      if (this.passwordResetFromEmail) {
        this.attemptPasswordReset()
      }

      if ((this.invalidLoginParams && this.attemptSignIn === true)
          || (this.invalidPasswordResetParams && this.attemptSignIn === false)) {
        this.isLoading = false
        return
      }

      if (this.attemptSignIn === true) {
        this.credentials = { Username: this.username, Password: this.password }
        // Sign in and get auth token
        await this.$store.dispatch('common/signIn', this.credentials)
        if (this.$store.state.common.isSignedIn === false) {
          this.isLoading = false
          this.loginError = true
          return
        }

        this.attemptSignIn = false
      }

      if (!this.$store.state.common.isSignedIn) {
        this.isLoading = false
        this.loginError = true
        this.attemptSignIn = true
        return
      }

      if (this.$store.state.common.needsPasswordReset === false) {
        if (this.$store.state.common.isMSAApproved === false && this.$store.state.common.userID !== 1) {
          this.isLoading = false
          this.$store.dispatch('common/setLoginError', 'MSA has not been approved.')  
          this.loginError = true
          this.showMSA = true
          return
        }
        this.routeFromSuccessfulLogin()
      } else if (!this.invalidPasswordResetParams) {
        this.attemptPasswordReset()
      }
    },
    async msaModalNo()
    {
      this.loginError = true
      this.showMSA = false
      this.attemptSignIn = true
      await this.$store.dispatch('common/signOut')
    },
    async msaModalYes()
    {
      this.showMSA = false
      this.routeFromSuccessfulLogin()
    },
    async requestResetPassword() {
      const baseUrl = window.location.toString().split('login')[0]
      const payload = {
        UserName: this.username,
        EmailAddress: this.email,
        URLStub: `${baseUrl}admin/ResetPassword`,
      }

      this.isLoading = true
      const response = await this.$store.dispatch('common/requestPasswordReset', payload)
      this.isLoading = false
      if (response) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email has been sent to your email address. Follow the directions in the email to reset your password.'
        this.snackbarImage = true
        this.showSnackbarBool = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Username or email invalid'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    async routeFromSuccessfulLogin() {
      await this.$store.dispatch('admin/getAllUserSettings')
      await this.$store.dispatch('workflow/setCustomFolderView', this.$store.state.workflow.customFolderViewSaved)

      let homeDate = new Date(this.$store.state.systemSettings.homePageDeployDateVersion1)
      if (!(homeDate instanceof Date && !isNaN(homeDate))) {
        homeDate = moment().format("MM/DD/YYYY")
        const config = {
          sConfigName: 'HomePageDeployDateVersion1',
          sConfigValue: homeDate,
        }
        this.$store.dispatch('common/setConfig', config)
      }
      if (this.$route && this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
        this.isLoading = false
        if (this.$route.query.redirect.indexOf('/document/') > -1) {
          this.$router.push({ path: "storage/vaults", query: { redirect: this.$route.query.redirect } })
        } else {
          this.$router.push({ path: this.$route.query.redirect })
        }
      } else if (this.defaultApp && this.defaultApp !== '') {
        this.isLoading = false
        this.$router.push({ name: this.defaultAppLocation })
      } else {
        this.isLoading = false
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        const firstApp = this.firstAccessibleAppLocation
        if (homeDate < threeMonthsAgo && firstApp) {
          this.$router.push({ name:  firstApp })
        } else {
          this.$router.push({ name: 'TheVasionHome' })
        }
      }
    },
    async samlRedirect() {
      await this.$store.dispatch('admin/generateSamlSSOLogin')
      const request = this.$store.state.admin.samlSSOLogin
      console.log(this.samlSSOUrl)
      // Redirect the browser to the SAML Identity Provider
      let samlURL = `${this.samlSSOUrl}?SAMLRequest=${request}`
      if (this.$route.query.redirect) {
        samlURL += `&RelayState=%7B%20LoginRedirect%3A%20%22${this.$route.query.redirect}%22%20%7D`
      }
      window.location.replace(samlURL)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.background-image-div {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorLabelStyle {
  font-family: $font-family-book;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $red-200;
}

.error-login {
  max-width: 400px;
}

.icon-fill {
  fill: $orange-300;
}

.innerLoginDiv {
  width: 85%;
  max-width: 420px;
}

#forgot-password-button {
  color: $grey-300;
  text-align: center;
  cursor: pointer;
}
#invalid-password-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $error;
  fill: $error;
  font-size: 17px;

  .vasion-circle-error {
    margin-right: 3px;
    padding-top: 2px;
  }
}

#loader-box {
  min-height: 36px;
  min-width: 419px;
  z-index: 30;
}

.keep-me-signed-in-checkbox {
  position: relative;
  top: 3px;
  right: 5px;
}

.login-button-div {
  position: relative;
  left: -7px;
  width: 103.5%;
}

.center {
  display: flex;
  justify-content: center;
  border: 1px solid grey
}

.loginDiv {
  width: calc(100vw);
  background-color: #252626;
  height: calc(100vh);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   transition: all 0s 50000s;
}

.loginSpacer24 {
  height: 24px;
}

.loginSpacer32 {
  height: 32px;
}

.loginSpacer48 {
  height: 48px;
}

.loginTextBox {
  width: 100%;
  color: $grey-300;
  background-color: rgba(0, 0, 0, 0.2);
  border-width: 0px;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.loginTextBoxError {
  border-width: 1px;
  border-color: red;
}

.loginTopSpacer {
  height: 100px;
}

.loginLabelStyle {
  font-family: Gotham Book;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aab8c1;
}

.validationErrorMessage {
  color: red;
}

.vasion-wordmark {
  width: 100%;
  position: relative;
  right: 8px;
}

.w100 {
  width: 100%;
  text-align: center;
}

.login-divider {
   width: 95%; 
   text-align: center; 
   border-bottom: 1px solid $grey-500; 
   line-height: 0.1em;
   margin: 30px 0px 30px 10px; 
} 

.login-divider span { 
    color: $white;
    background: #222323; 
    padding:0 10px; 
}

.privacy {
  font-size: 12px;
  color: $grey-500;
}

::v-deep #saml-login-submit .vasion-secondary {
    border: solid 1px $white !important;
    color: $black !important;
    background-color: $white !important;

    &:hover, &:focus {
      background-color: $btn-secondary-hover-bg !important;
    }
}

a {
  color: $menu-secondary-color !important;
}
</style>
